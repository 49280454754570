/* eslint-env jquery */
/* global debounce */
require( 'slick-carousel-latest' );
import { slideDirection, changeActiveSlide , videoInitFunc,
  videoAfterChangeFunc,
  slickObserver} from '../custom/general-funtions';

( function ( $, w ) {
  let windowWidth = $( w ).width();

  const initSlick = () => {
    if ( $( '[data-slick]' ).length ) {
      $( '.bs-section--add-progress-bar' ).each( ( i, slck ) => {
        $( slck ).find( '[data-slick]' ).last().sliderProgress();
      } );
      $( '.bs-slider--platform-being-mobile-first [data-slick]' ).each( ( i, slck ) => {
        $( slck ).pauseOnHoverFalse();
      } );
      $( '[data-slick]' )
        .not( '.bs-slider-accordion-slick' )
        .not( '.slick-initialized' )
        .not( '.bs-section--award-and-recognition .bs-slider--about-awards .slick-slider' )
        .not( '.bs-section--why-how-kobiton-vtab .bs-slider-content' )
        .slick();
    }
  };

  const autoPlaySpeedFunction = () => {
    $( '.bs-tab-slider--progress-bar :not(.bs-slider-content)[data-slick]' ).each( ( index, ele ) => {
      if ( $( ele ).hasClass( 'slick-initialized' ) ) {
        const slick = $( ele ).slick( 'getSlick' );

        //append wrapper class and animator span to each slick slide element
        $.each( $( ele ).find( '.slick-slide' ), ( i, element ) => {
          const animatorElement = $( element ).find( '.slick-slide-wrapper .slick-slide-animator' );
          animatorElement.unwrap();
          animatorElement.remove();

          $(element).find('.slick-slide-wrapper').children().wrapAll('<div class="slick-slide-title-wrapper"></div>'); // eslint-disable-line
          const $content = $( element ).find( '.slick-slide-title-wrapper' );
          $content.append( '<span class="slick-slide-animator"></span>' );
        } );
        //set animator animation-duration
        if ( slick.options.autoplay === true ) {
          const playSpeed = slick.options.autoplaySpeed;
          $( ele )
            .find( '.slick-slide-animator' )
            .attr( 'data-speed', `${playSpeed}ms` )
            .css( 'animation-duration', `${playSpeed}ms` );
        }
      }
    } );
  };

  const refreshSlick = () => {
    $( '.slick-initialized' ).each( ( _, element ) => {
      if ( element?.slick ) {
        element.slick.refresh();
      }
    } );
  };

  // To reset the autoplay enabled sliders to the initial slide when appearing on the viewport
  const resetSlidersWithIntersectionObserver = () => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0,
    };

    const io = new IntersectionObserver( entries => {
      entries.forEach( entry => {
        if ( entry.isIntersecting ) {
          $.each( $( '.slick-initialized' ), ( index, element ) => {
            const slickSlider = $( element );
            const slickObject = slickSlider.slick( 'getSlick' );
            const initialSlide = slickObject.options.initialSlide;
            // Resetting only if the current slide is greater than the initial slide
            if ( slickObject.options.autoplay && slickObject.currentSlide > initialSlide ) {
              slickSlider.slick( 'slickGoTo', initialSlide );
            }
          } );
        }
      } );
    }, options );

    $.each( $( '.bs-slider' ).closest( '.bs-section' ), ( index, section ) => {
      io.observe( section );
    } );
    $.each( $( '.bs-tab-slider' ).closest( '.bs-section' ), ( index, section ) => {
      io.observe( section );
    } );
  };

  const debouncedHandlers = () => {
    const dynamicWidth = $( w ).width();
    if ( windowWidth !== dynamicWidth ) {
      initSlick();
      autoPlaySpeedFunction();
      refreshSlick();
      windowWidth = dynamicWidth;
    }
  };

  // To slide between first slide and last slide smoothly when the 'Infinite' mode is enabled
  const slider = $( '[data-slick]' );
  slider
    .on( 'init reinit', ( e, slick ) => {
      $( e.target ).find( '.slick-slide' ).slice( 0, slick.options.slidesToShow ).addClass( 'bs-slick-inview' );
      videoInitFunc( $( e.target ) );
      slickObserver( $( e.target ), slick );
      changeActiveSlide( e );
    } )
    .on( 'beforeChange', ( e, slick, currentSlide, nextSlide ) => {
      if ( slick.options.infinite ) {
        const slidesCount = slick.$slides.length,
          cls = 'slick-current slick-active' + ( slick.options.centerMode ? ' slick-center' : '' );
        let selector = null;
        slideDirection( e, slick, currentSlide, nextSlide );
        if ( nextSlide === 0 ) {
          selector = `.slick-slide[data-slick-index="0"], .slick-slide[data-slick-index="${slidesCount}"]`;
        } else if ( nextSlide === slidesCount - 1 ) {
          selector = `.slick-slide[data-slick-index="-1"], .slick-slide[data-slick-index="${slidesCount - 1}"]`;
        }

        if ( !selector ) return;
        // Adding a timeout since we need to add the active class after the transition has started
        setTimeout( () => {
          $( '.slick-slide', slick.$slider ).removeClass( cls );
          $( selector, slick.$slider ).addClass( cls );
        }, 10 );
      }
      $( e.target ).addClass( 'bs-slide-moving' );
    } )
    .on( 'afterChange', ( e, slick ) => {
      $( e.target ).removeClass( 'bs-slide-moving bs-slide-move-right bs-slide-move-left' );
      videoAfterChangeFunc( $( e.target ), slick );
    } );

  // Calls when the window is fully loaded
  $( w ).on( 'load', () => {
    initSlick();
    autoPlaySpeedFunction();
    resetSlidersWithIntersectionObserver();
  } );

  // Calls on window resize
  $( w ).on( 'resize', debounce( debouncedHandlers, 500 ) );

  // add slider progress bar and counter
  $.fn.sliderProgress = function () {
    if ( $.fn.slick && typeof $.fn.slick !== 'function' ) return;
    const _this = this;
    const _count = _this.find( '.slick-slide-wrapper' ).length;
    _this
      .on( 'beforeChange', ( event, slick, currentSlide, nextSlide ) => {
        const progressWidth = ( ( nextSlide + 1 ) / _count ) * 100;
        _this
          .parent()
          .find( '.active-slide-id' )
          .text( nextSlide + 1 );
        _this
          .parent()
          .find( '.bs-slider-progress__anim' )
          .css( {
            width: progressWidth + '%',
            transitionDuration: slick.options.speed + 'ms',
          } );
      } )
      .on( 'init', function ( event, slick ) {
        const currSlide = slick.currentSlide;
        if ( _this.parent().find( '.bs-slider-progress' ).length === 0 ) {
          _this.parent().append(
            `
								<div class="bs-slider-progress">
									<div class="bs-slider-progress__bar">
										<span class="bs-slider-progress__anim"></span>
									</div>
									<div class="bs-slider-progress__counter-wrapper">
										<span class="active-slide-id">
										${currSlide + 1}</span>/<span class="slide-count">${_count}
										</span>
									</div>
								</div>
							`
          );
        } else {
          _this
            .parent()
            .find( '.active-slide-id' )
            .text( currSlide + 1 );
        }
        _this
          .parent()
          .find( '.bs-slider-progress__anim' )
          .css( {
            width: ( ( currSlide + 1 ) / _count ) * 100 + '%',
            transitionDuration: slick.options.speed + 'ms',
          } );
      } );
  };
  // add pauseOnHover to false
  $.fn.pauseOnHoverFalse = function ( options ) {
    const configs = $.extend(
      {
        pauseOnHover: false,
        pauseOnFocus: false,
      },
      options
    );
    const currSlider = this,
      currSlickData = currSlider.data( 'slick' );
    // eslint-disable-next-line array-callback-return
    Object.values( currSlickData.responsive ).map( point => {
      if ( point.settings !== 'unslick' ) {
        point.settings.pauseOnHover = configs.pauseOnHover;
        point.settings.pauseOnFocus = configs.pauseOnFocus;
      }
    } );
    currSlider.data( 'slick', currSlickData );
  };
} )( jQuery, window );
