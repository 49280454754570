const teaserPlayPause = ( currentItem, action ) => {
  if ( $( currentItem ).find( 'video' ).length > 0 ) {
    $( currentItem ).find( 'video' ).trigger( action );
  }
};

// check slide direction
const slideDirection = ( e, slick, currentSlide, nextSlide ) => {
  let dir;
  if( ( currentSlide < nextSlide && currentSlide === nextSlide-1 ) || 
			( currentSlide === slick.slideCount-1 && nextSlide === 0 ) )
  {
    dir='right';
  }
  else if( nextSlide < currentSlide || ( nextSlide === slick.slideCount-1 && currentSlide === 0 ) )
  {
    dir='left';
  }
  $( e.target ).addClass( `bs-slide-move-${dir}` );
};

// when click on popup active the slide
const changeActiveSlide = ( e ) => {
  $( e.target ).on( 'click', '.slick-slide:not(.slick-current) .media-elements [data-fancybox]', ( eve ) => {
    const clickedId = $( eve.currentTarget )
      .parents( '.slick-slide' )
      .attr( 'data-slick-index' );
    $( e.target ).slick( 'slickGoTo', clickedId );
  } );
};

// video init function
const videoInitFunc = ( slickSlider ) => {
  const videos = slickSlider.find( '.slick-slide video' );
  videos.each( ( index, element ) => {
    $( element ).get( 0 ).pause();
    $( element ).get( 0 ).currentTime = 0;
  } );
};
// video after change
const videoAfterChangeFunc = ( slickSlider, slick ) => {
  slickSlider.find( '.slick-slide video' ).each( ( index, element ) => {
    $( element ).get( 0 ).pause();
  } );
  const currSlide = slickSlider.find( '.slick-slide' ).not( '.slick-cloned' ).eq( slick.currentSlide );
  if ( currSlide.find( 'video[autoplay]' ).length > 0 && $( window ).width() > 768 ) {
    setTimeout( () => {
      currSlide.find( 'video[autoplay]' ).get( 0 ).play();
      currSlide.find( 'video[autoplay]' ).not( 'video[muted]' ).eq( 0 ).prop( 'muted', false );
    }, slick.options.speed );
  }
};
// slick observe
const slickObserver = ( slickSlider, slick ) => {
  const slickObserve = new IntersectionObserver( 
    entries => {
      const entry = entries[0];
      const currentSlide = slickSlider.find( '.slick-slide' ).not( '.slick-cloned' ).eq( slick.currentSlide );
      if ( entry.isIntersecting ) {
        if ( currentSlide.find( 'video[autoplay]' ).length > 0 ) {
          currentSlide.find( 'video' ).get( 0 ).play();
        }
      } else {
        if ( currentSlide.find( 'video[autoplay]' ).length > 0 ) {
          currentSlide.find( 'video' ).get( 0 ).pause();
        }
      }
    },
    { root: null, threshold: 0.5 } );
  slickObserve.observe( slickSlider[0] );
};

export { teaserPlayPause, slideDirection, changeActiveSlide, videoInitFunc, videoAfterChangeFunc, slickObserver };
